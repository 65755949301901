<template>
  <v-card flat class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <create-giving-video-form
        ref="givingVideoForm"
        :key="componentKey"
        :form="form.givingVideo"
        :itemId="itemId"
      >
      </create-giving-video-form>
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="bg-secondary text-color-white"
        @click="submit()"
        :loading="loading"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const givingVideosRepository = RepositoryFactory.get("giving-videos");

const CreateGivingVideoForm = () =>
  import("../forms/CreateGivingVideoForm.vue");

export default {
  components: {
    CreateGivingVideoForm
  },
  data: () => ({
    componentKey: 0,
    title: "Update Default Giving Video",
    loading: false,
    itemId: null,
    form: {},
    eventName: "reloadItems_givingVideos"
  }),
  methods: {
    showDetails(data) {
      /**
       * Uncomment time related variables when time picker is needed
       */
      let date = null;
      // let time = null;

      if (data.scheduled_at) {
        date = data.scheduled_at.substring(0, 10);
        // time = data.scheduled_at.substring(11, 16);
      }

      console.log({ data });

      this.form = {
        givingVideo: {
          title: data.title,
          description: data.description,
          date
          // time
        }
      };
    },
    update(form) {
      givingVideosRepository
        .update(this.itemId, form)
        .then(response => {
          console.log({ response }, "update");
          GLOBAL_EVENT_BUS.$emit(this.eventName);

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Updated.");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(form) {
      givingVideosRepository
        .create(form)
        .then(response => {
          console.log({ response }, "create");
          GLOBAL_EVENT_BUS.$emit(this.eventName);

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submit() {
      let givingVideoForm = this.$refs.givingVideoForm;

      let valid = givingVideoForm.validate();

      if (valid) {
        this.loading = true;

        let form = { ...givingVideoForm.form };

        /**
         * Uncomment this if time picker is needed
         */
        // form.scheduled_at = `${form.date} ${form.time}:00`;

        // form.scheduled_at = `${form.date} 00:00:00`;

        // form = Object.assign(form, {
        //     scheduled_at: moment(`${form.date} 00:00:00`).utc().format("YYYY-MM-DD HH:mm:ss")
        // });

        let formData = this.setFormData(form);

        if (this.itemId) {
          await this.update(formData)
        }
        // else {
        //   await this.create(formData)
        // }

        this.resetForm();
      }
    },
    setFormData(form) {
      console.log({ form }, "setFormData");

      if (form.video_file) {
        let formData = new FormData();

        formData.append("label", form.label);
        formData.append("description", form.description);
        formData.append("video_file", form.video_file);
        // formData.append("url", "https://inspire-church-staging-bucket.s3-ap-southeast-1.amazonaws.com/45511917_105901957966498_5872396261356274975_n.mp4");

        if (this.itemId) {
          formData.append("_method", "PUT");
        }

        return formData;
      }

      if (this.itemId) {
        form._method = "PUT";
      }

      return form;
    },
    async getDefaultGivingVideo() {
      await givingVideosRepository.default()
        .then(response => {
          console.log({ response })
          console.log(response.data.responseData.id)
          this.itemId = response.data.responseData.id;
        })
        .catch(error => {
          console.log({ error })
        })
    },
    resetForm() {
      this.componentKey++;
      this.form = {};
      this.itemId = null;
    }
  },
  mounted() {
    this.getDefaultGivingVideo();
  }
};
</script>
